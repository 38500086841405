import './App.css';
import React from 'react';
import Header from "./partials/header";
import Footer from "./partials/footer";
import { Helmet } from "react-helmet";
import bocian from "./assets/images/bocian-na-urlopie.png";
import { VideoJS }  from './components/videojs';
import bocian_bg from './assets/images/bocian-bg.jpg';

function App() {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    language: 'pl',
    normalizeAutoplay: true,
    fluid: true,
    controlBar: {
      'pictureInPictureToggle': true,
      'volumePanel': false,
    },
    sources: [{
      src: 'https://cam.lokalna.net/hls/bociany_zywocice/index.m3u8',
      type: 'application/x-mpegURL'
    }],
    poster: bocian_bg,
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      // videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      // videojs.log('player will dispose');
    });
  };
  return (
    <>
        <Helmet>

        </Helmet>
        <div className="App">
            <main>
                <Header/>
                <div className="max-w-screen-xl m-auto">
                    <h1 className="my-16 font-semibold text-2xl md:text-3xl text-center text-gray-800">Żywocickie
                        bociany on-line - Kamera Live</h1>
                </div>
          <div className="mx-2 md:mx-auto md:max-w-screen-md my-12 border-b-4 border-main-blue">
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} className=""/>
            {/* <img src={bocian} alt="Bocian na urlopie" /> */}
          </div>
          <Footer />
      </main>
      </div>
    </>

  );
}

export default App;
