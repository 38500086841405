import logoChespa from '../assets/images/loga/chespa-logo.png';
import logoKsi from '../assets/images/loga/ksi-logo.png';
import logoTCF from '../assets/images/loga/tcf-logo.png';
import logoOSP from '../assets/images/loga/osp-logo.png';


export default function Footer() {
    return (
        <div className="max-w-screen-xl m-auto">
            <h2 className='mx-auto text-center text-gray-500'>Transmisja możliwa dzięki:</h2>
            <div className="flex grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 py-12">
                <a className="col-span-1" href="https://www.chespa.eu">
                    <img src={logoChespa} className="h-12 m-auto" alt="Logo Chespa"/>
                    <p className='mx-auto text-center text-gray-400 text-sm py-2'>Chespa Sp. z o.o.</p>
                </a>
                <a className="col-span-1" href="https://www.ksinternet.pl">
                    <img src={logoKsi} className="h-12  m-auto" alt="Logo KSI"/>
                    <p className='mx-auto text-center text-gray-400 text-sm py-2'>Krapkowickie sieci internetowe</p>
                </a>
                <a className="col-span-1" href="https://osp.zywocice.pl">
                    <img src={logoOSP} className="h-12  m-auto" alt="Logo OSP"/>
                    <p className='mx-auto text-center text-gray-400 text-sm py-2'>OSP Żywocice</p>
                </a>
                <a className="col-span-1" href="https://thecodefoxes.com">
                    <img src={logoTCF} className="h-12  m-auto" alt="Logo TCF"/>
                    <p className='mx-auto text-center text-gray-400 text-sm py-2'>TheCodeFoxes - Krzysztof Joszko</p>
                </a>
            </div>
            <hr className="my-6 border-gray-100 sm:mx-auto lg:my-8" />
            <div className="flex grid grid-cols-1 md:grid-cols-2 mx-auto my-6">
                <span className="mx-auto md:ml-0 md:mr-auto block text-sm text-gray-500 sm:text-center">© 2025 <a href="https://zywocice.pl/" className="hover:underline">zywocice.pl</a></span>
                <p className="mx-auto md:mr-0 md:ml-auto my-auto text-gray-600 text-center">      
                    Projekt i realizacja
                    <a href="https://thecodefoxes.com" className="px-2 text-main-green font-semibold hover:underline">
                        TheCodeFoxes - Krzysztof Joszko
                    </a></p>
            </div>
        </div>
    );
}